<template>
  <div class="testimonial-slider content-container-template">
    <UiHeader
      :header="header"
      :subheader="subheader"
      :header-position="headerPosition"
      :header-layout="headerLayout"
    />

    <div class="testimonial-slider__slider-wrapper">
      <UiSwiper :options="swiperOptions" class="testimonial-slider__slider">
        <SwiperSlide
          v-for="item in itemList"
          :key="item.id"
          class="testimonial-slider__slide"
        >
          <SvgIconQuote class="testimonial-slider__slide-quote" />

          <T3HtmlParser
            :content="item.text"
            class="testimonial-slider__slide-text"
          />

          <div class="testimonial-slider__slide-footer">
            <component
              :is="item.link ? 't3-link' : 'div'"
              :to="item.link || null"
              class="testimonial-slider__slide-logo"
            >
              <UiImg :height="18" :image="item.logo" />
            </component>

            <div class="testimonial-slider__slide-label" v-text="item.label" />
          </div>
        </SwiperSlide>
      </UiSwiper>

      <div v-if="itemList.length > 1" class="testimonial-slider-controls">
        <button ref="prev" class="swiper-button swiper-button-prev">
          <SvgIconArrowNavigation />
        </button>
        <div ref="pagination" class="swiper-pagination" />
        <button ref="next" class="swiper-button swiper-button-next">
          <SvgIconArrowNavigation />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import SvgIconArrowNavigation from '~ui/assets/new-icons/icon-arrow-navigation.svg'
import SvgIconQuote from '~ui/assets/new-icons/icon-quote-rounded.svg'
import { UiHeader, UiImg, UiSwiper } from '~ui/components'

const props = defineProps<UiCeProductBoxesProps>()

const next = ref(null)
const prev = ref(null)
const pagination = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    enabled: props.itemList.length > 1,
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    spaceBetween: 20,
    breakpoints: {
      768: { spaceBetween: 32 }
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value
    },
    pagination: {
      el: pagination.value,
      clickable: true
    }
  })
)
</script>

<style lang="scss">
.testimonial-slider {
  position: relative;

  @include media-query(max sm) {
    overflow: hidden;
  }

  &::before {
    @include media-query(sm) {
      content: '';
    }

    position: absolute;
    inset-block: 0;
    z-index: z-index(over);
    width: 1px;
    background-color: var(--color__border-color);

    right: rem($container-padding);

    @include media-query(lg) {
      right: 0;
    }
  }

  & .ui-header {
    display: grid;
    gap: rem(10px);
    padding-block: rem(16px);

    @include media-query(sm) {
      padding-left: rem($container-padding);
      border-left: 1px solid var(--color__border-color);
    }

    &__title {
      margin: 0;
    }

    &__subtitle {
      font-size: rem(18px);
      color: var(--color__font-color--subheading);
    }
  }

  &__slider-wrapper {
    padding-bottom: rem(24px);
  }

  & > .ui-header + &__slider-wrapper {
    @include media-query(max sm) {
      margin-top: rem(32px);
    }

    @include media-query(sm) {
      margin-top: rem(48px);
      padding-left: rem($container-padding);
    }
  }

  & .swiper {
    margin-bottom: rem(32px);

    @include media-query(max sm) {
      overflow: visible;
    }
  }

  &__slide {
    $slide-width: rem(720px);

    width: min(95%, $slide-width);
    max-width: $slide-width;
    color: color(grey-800);
    padding: rem($container-padding);
    border-radius: em(4px);
    border: 1px solid color(grey-200);
    background-color: color(white);

    &-quote path {
      fill: color(grey-300);
    }

    &-quote,
    &-text {
      margin-bottom: rem(32px);
    }

    &-text {
      color: color(grey-450);

      & strong {
        color: color(grey-800);
      }

      & * {
        margin: 0;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
    }

    &-logo {
      padding-block: rem(12px);

      & img {
        height: rem(18px);
      }
    }

    &-label {
      font-size: rem(18px);
      font-weight: 500;
      color: color(primary);
      position: relative;
    }

    &-logo + &-label {
      padding-left: rem(32px);

      &::before {
        content: '';
        top: 0;
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        background-color: color(grey-250);
        left: rem(16px);
      }
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: rem(512px);
    gap: rem(16px);

    & .swiper-button {
      width: rem(48px);
      height: rem(48px);
      border-radius: rem(4px);
      border: 1px solid color(grey-300);
      background-color: color(white);
      flex-shrink: 0;

      transition: border-color 0.2s;

      & svg path {
        transition: fill 0.2s;
        fill: color(grey-450);
      }

      &-next svg {
        rotate: 180deg;
      }

      &::after {
        content: none;
      }

      &-disabled {
        opacity: 1;
        border: 1px solid color(grey-250);

        & svg path {
          fill: color(grey-300);
        }
      }

      @include media-query(max sm) {
        display: none;
      }
    }

    & .swiper-pagination {
      position: static;
      width: 100%;
      height: rem(48px);

      &-bullet {
        height: 100%;
        width: 100%;
        width: rem(80px);
        padding-inline: rem(8px);

        &::before {
          background-color: color(grey-250);
          border-radius: 1px;
        }

        &-active::before {
          background-color: color(blue-1000);
        }
      }
    }
  }
}
</style>
